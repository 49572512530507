<template>
<v-navigation-drawer :width="width" class="elevation-5" fixed right>
  <v-container v-if="milestone">
    <v-row class="pa-3 mb-2 align-end">
      <div v-if="milestone.ms_progress==100" class="d-flex">
        <v-icon>mdi-check</v-icon>
        <div class="text-h6 grey--text">已完成</div>
      </div>
      <div v-else class="d-flex">
        <v-icon>mdi-timer-sand-empty</v-icon>
        <div class="text-h6 grey--text">未完成</div>
      </div>
      <div class="text-caption grey--text">（系统自动生成）</div>
      <v-spacer></v-spacer>
      <!-- delete milestone -->
      <v-hover v-slot="{ hover }">
        <v-icon @click="deleteMS" :color="hover ? 'red' : 'grey'" large>mdi-delete-circle-outline</v-icon>
      </v-hover>
      <!-- close editor -->
      <v-hover v-slot="{ hover }">
        <v-icon @click="close" :color="hover ? 'red' : 'grey'" large>mdi-close</v-icon>
      </v-hover>
    </v-row>
    <v-divider></v-divider>
    <v-row class="pa-8 align-center">
      <!-- milestone owner -->
      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-avatar v-bind="attrs" v-on="on" size="36" color="green">
            <div class="white--text text-h6">{{ milestone.ms_owner | surename }}</div>
          </v-avatar>
        </template>
        <UserPicker @pick="changeMSOwner" />
      </v-menu>
      <!-- milestone name -->
      <input @keydown.enter="changeName" class="ml-3 text-h6" full-width v-model="milestone.ms_name"></input>
    </v-row>
    <!-- description -->
    <v-row class="px-8">
      <div v-if="!description" @click="description=true" class="blue--text text-subtitle-1">添加里程碑描述</div>
      <v-textarea v-model="milestone.ms_description" v-else outlined></v-textarea>
    </v-row>
    <v-row class="justify-end px-8" v-if="description">
      <v-btn @click="description=false" class="mx-1" small>取消</v-btn>
      <v-btn @click="changeDes" class="mx-1" small color="primary">确定</v-btn>
    </v-row>
    <!-- milestone date range -->
    <v-row class="pa-5">
      <div style="width:100%;height:100px;" class="blue lighten-5 d-flex mx-5">
        <v-col cols="9">
          <div style="padding-left:100px;" :class="`text-h5 ${range.color}--text`">{{ range.text }}</div>
          <div class="d-flex align-center mx-5">
            <span>{{ milestone.ms_start_date }}</span>
            <v-divider class="mx-3"></v-divider>
            <span>{{ milestone.ms_end_date }}</span>
            <date-range-picker ref="picker" opens="left" control-container-class="blue lighten-5 ml-3" :locale-data="{ 
                firstDay: 1,
                format: 'yyyy-mm-dd',
                applyLabel: '确定',
                cancelLabel: '取消',
                daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
              }" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="{startDate: milestone.ms_start_date, endDate: milestone.ms_end_date }" :ranges="false" @update="changeDate" :append-to-body="true">
              <template v-slot:input="picker" style="min-width: 350px;">
                <v-icon small>mdi-pencil-outline</v-icon>
              </template>
            </date-range-picker>
          </div>
        </v-col cols="3">
        <v-divider vertical></v-divider>
        <!-- milestone progress -->
        <v-col class="pa-6">
          <v-progress-circular size="50" :value="milestone.ms_progress">{{ milestone.ms_progress }}%</v-progress-circular>
        </v-col>
      </div>
    </v-row>
    <v-row class="pa-5">
      <v-tabs v-model="tab">
        <v-tab>
          <div>
            <v-icon>mdi-notebook-edit-outline</v-icon>
            <span>进展<span v-if="msProgress.length>0"> ({{ msProgress.length }})</span></span>
          </div>
        </v-tab>
        <v-tab>
          <div>
            <v-icon>mdi-comment-processing-outline</v-icon>
            <span>互动</span>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- progress -->
        <v-tab-item>
          <v-container fluid>
            <v-row v-if="!openProgress" class="pa-3">
              <v-btn @click="openProgressForm" class="justify-start" height="50" color="grey" tile outlined width="420">
                <div class="black--text text-subtitle-1">填写进展</div>
              </v-btn>
            </v-row>
            <v-row class="pa-3">
              <ProgressForm @update="createMsProgress" v-if="openProgress" v-bind:progress="newProgress" />
            </v-row>
            <!-- progress list -->
            <v-row v-if="msProgress.length>0" class="px-3">
              <div style="width:430px;" class="mt-3 mx-3 pa-2 blue lighten-5" v-for="(p, i) in msProgress" :key="i">
                <div class="d-flex">
                  <v-icon color="orange">mdi-account-outline</v-icon>
                  <div class="orange--text text-subtitle-1 ml-3">填写人: {{ p.pr_creator | username }}</div>
                  <v-spacer></v-spacer>
                  <!-- buttons for progress -->
                  <v-hover v-slot="{ hover }" open-delay="100">
                    <div>
                      <div v-if="!hover" style="width:100px;height:20px;"></div>
                      <v-icon v-if="hover" @click="openEditProgress(p.pr_id)" class="mx-1">mdi-pencil-outline</v-icon>
                      <v-icon v-if="hover" @click="removeProgress(p.pr_id)" class="mx-1">mdi-delete-outline</v-icon>
                    </div>
                  </v-hover>
                </div>
                <div class="text-caption grey--text pl-2 mt-2">{{ p.created_at | prFormat }} 添加了进展</div>
                <div v-if="editProgress==p.pr_id">
                  <editor-content :content="p.pr_description" @input="inputProgressDes"></editor-content>
                  <div style="border:1px solid grey;border-top:none;" class="d-flex py-2">
                    <v-spacer></v-spacer>
                    <v-btn @click="editProgress=null" class="mx-1" small>取消</v-btn>
                    <v-btn @click="updateDescription(p.pr_id)" class="mx-1" color="primary" small>确定</v-btn>
                  </div>
                </div>
                <div v-else class="pl-2 mt-2" v-html="p.pr_description"></div>
                <div class="d-flex">
                  <div class="text-caption grey--text pl-2">里程碑当前进度为{{p.pr_percent}}%</div>
                  <v-spacer></v-spacer>
                  <!-- change progress date -->
                  <date-range-picker ref="picker" opens="left" control-container-class="grey--text" :locale-data="{ 
                      firstDay: 1,
                      format: 'yyyy-mm-dd',
                      applyLabel: '确定',
                      cancelLabel: '取消',
                      daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                      monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                    }" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="{startDate: p.pr_start_date, endDate: p.pr_end_date}" :ranges="false" @update="datePrPicker($event, p.pr_id)" :append-to-body="true">
                    <template v-slot:input="picker">
                      <v-hover v-slot="{ hover }" open-delay="100">
                        <div style="cursor:pointer;" :class="hover ? 'text-caption blue--text':'text-caption'">进展日期: {{ picker.endDate | prFormatNoTime }}</div>
                      </v-hover>
                    </template>
                  </date-range-picker>
                </div>
                <div class="mt-4 mx-n2">
                  <v-card color="#B0C4DE" class="pa-2 my-1" outlined v-for="(file, i) in p.pr_files" :key="i">
                    <div class="d-flex px-3">
                      <div class="blue--text">
                        {{ file.file_name }}
                      </div>
                      <v-spacer></v-spacer>
                      <a style="text-decoration:none;" :href="`${url}/${file.file_id}`">
                        <v-icon>mdi-download</v-icon>
                      </a>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-row>
            <v-row class="px-3" v-else>
              <div class="pt-8 pl-12" style="width:100%;height:100px;border: 1px dashed grey;">
                <div class="grey--text text-subtitle-1">需要分解具体的事吗？试试添加新的进展</div>
              </div>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
					<v-container fluid style="width:100%;">
						<v-row>
							<Operating :op_obj="{op_parent: this.milestone.ms_id,op_parent_type: 13}" />
						</v-row>
						<v-row>
							<LikingComponent :rp_id="this.milestone.ms_id" rp_type="13"/> 
						</v-row>
					</v-container>
          
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</v-navigation-drawer>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import * as endpoint from "@/config/endpoints";
export default {
  name: 'MilestoneEditor',
  components: {
    UserPicker: () => import('@/components/common/UserPicker.vue'),
    Operating: () => import('@/components/common/operating/Operating.vue'),
		LikingComponent:() => import('@/components/summary/LikingComponent.vue'),
    DateRangePicker,
    ProgressForm: () => import('@/components/common/ProgressForm.vue'),
    EditorContent: () => import('@/components/okrgoal/rightInfo/editorContent/EditorContent.vue'),
    DateRangePicker,
  },
  data() {
    return {
      width: 0,
      milestone: null,
      description: false,
      tab: null,
      openProgress: false,
      newProgress: null,
      editProgress: null,
      prText: '',
      url: endpoint.FILE_DOWNLOAD
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    ...mapGetters('progress', ['msProgress']),
    // ...mapGetters('fileUpload', ['uploadedFiles']),
    range: function () {
      let temp;
      const today = new Date();
      const endDay = new Date(this.milestone.ms_end_date);
      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round((endDay - today) / oneDay) + 1;
      if (diffDays >= 0) {
        temp = {
          text: `剩余${diffDays}天`,
          color: "blue"
        }
      } else {
        temp = {
          text: `过期${-diffDays}天`,
          color: "red"
        }
      }
      return temp
    }
  },
  methods: {
    ...mapActions('milestone', ['updateMilestone', 'removeMilestone']),
    ...mapActions('progress', ['addMsProgress', 'getMsProgress', 'deleteMsProgress', 'updateMsProgress']),
    ...mapActions('fileUpload', ['fileDownload']),
    open(ms) {
      this.width = 500;
      this.milestone = ms;
      this.openProgress = false;
      this.getMsProgress({
        pr_parent: ms.ms_id
      });
      // this.getFiles();
    },
    close() {
      this.width = 0;
    },
    deleteMS() {
      this.removeMilestone({
        ms_id: this.milestone.ms_id,
        ms_parent_item: this.milestone.ms_parent_item
      })
      this.close();
    },
    changeMSOwner(e) {
      this.milestone.ms_owner = e.user.id;
      this.updateMilestone({
        ms_id: this.milestone.ms_id,
        ms_owner: this.milestone.ms_owner,
        ms_parent_item: this.milestone.ms_parent_item
      })
    },
    changeName() {
      this.updateMilestone({
        ms_id: this.milestone.ms_id,
        ms_name: this.milestone.ms_name,
        ms_parent_item: this.milestone.ms_parent_item
      })
    },
    changeDes() {
      this.description = false;
      this.updateMilestone({
        ms_id: this.milestone.ms_id,
        ms_description: this.milestone.ms_description,
        ms_parent_item: this.milestone.ms_parent_item
      })
    },
    changeDate(e) {
      let start = e.startDate;
      let end = e.endDate;
      start = new Date(start);
      end = new Date(end);
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      this.milestone.ms_start_date = start;
      this.milestone.ms_end_date = end;
      this.updateMilestone({
        ms_id: this.milestone.ms_id,
        ms_start_date: this.milestone.ms_start_date,
        ms_end_date: this.milestone.ms_end_date,
        ms_parent_item: this.milestone.ms_parent_item
      })
    },
    openProgressForm() {
      this.openProgress = true;
      let today = new Date();
      today = `${today.getFullYear()}/${today.getMonth()+1}/${today.getDate()} ${today.getHours()}:${today.getMinutes()}`;
      this.newProgress = {
        pr_parent_type: 13,
        pr_parent: this.milestone.ms_id,
        pr_creator: this.authUser.id,
        pr_start_date: today,
        pr_end_date: today,
        pr_description: '',
        pr_percent: this.milestone.ms_progress
      }
    },
    createMsProgress(e) {
      if (!!e) {
        this.newProgress.pr_uploaded_file = e;
      }
      
      this.addMsProgress(this.newProgress);
      this.openProgress = false;
    },
    removeProgress(id) {
      this.deleteMsProgress({
        pr_id: id
      });
    },
    updateDescription(id) {
      this.updateMsProgress({
        pr_id: id,
        pr_description: this.prText
      });
      this.editProgress = null;
    },
    inputProgressDes(e) {
      this.prText = e.prgValue;
    },
    openEditProgress(id) {
      this.editProgress = id;
      const index = this.msProgress.findIndex(pr => pr.pr_id == id);
      this.prText = this.msProgress[index].pr_description;
    },
    datePrPicker(e, id) {
      const start = moment(e.startDate).format('YYYY-MM-DD');
      const end = moment(e.endDate).format('YYYY-MM-DD');
      this.updateMsProgress({
        pr_id: id,
        pr_start_date: start,
        pr_end_date: end
      });
    }
  },
  filters: {
    prFormat(date) {
      const day = new Date(date);
      day.setDate(day.getDate() + 1);
      return moment(day).format('YYYY/MM/DD HH:mm')
    },
    prFormatNoTime(date) {
      const day = new Date(date);
      day.setDate(day.getDate() + 1);
      return moment(day).format('YYYY/MM/DD')
    },
  }
}
</script>

<style>
input:focus {
  outline: none;
  width: 300px;
}
</style>
